@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: local("ProximaNova"),
    url(./proximaNova/ProximaNova-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./proximaNova/ProximaNova-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  src: local("ProximaNova"),
    url(./proximaNova/ProximaNova-Extrabld.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url(./proximaNova/ProximaNova-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "proxima-nova-extra-condensed";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./proximaNova/ProximaNovaExCn-Extrabld.ttf) format("truetype");
}

.font-link {
  font-family: "Barlow", sans-serif;
}

.font-Mont {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: "10px";
  box-shadow: "inset 0 0 2px #b161ce";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b161ce;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b161ce;
}

:root {
  --clr-black: #000;
  --clr-white: #fff;
}

.flex-all-center: {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
