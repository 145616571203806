@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #2a4c4b; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  background-image: linear-gradient(39.47deg, #8416e0 16.41%, #ff2cc5 85.97%);
}
.lightmode-active .MuiDrawer-paper .MuiSwitch-root .MuiSwitch-thumb::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGlSURBVHgB7ZeLcYMwDIZFjwHoBu4GjEA3yAgZIRuETtBuQLpBN6CdINcJYIOwgSoV+6LQ2PhBr/Qu353PgGzrxzaSAbgxkkECiLihqqAyZFn2Bn8BiWhxpIME7mAFrEJEbjPQFO+p4jV/pPUeLM1eqXxQsdnNWA1VPY3zBL5Qpx2eOVIpIBIWIMbahXRUvNlE5w1EoMc5mc3L9yH9pZDtFVtF5Vl/HZ2uG35+pW2pZ1NBDNNl0MJadNNFO/QQNF0iF7wEJSxJoAApRMFS4OUOD6H1Gd8rd/BbwZgjYrh3xJlvcuFISQN17PXzKkEAw5/3weUjF0aZhNj4oK9TN5gS1xw5K3NDfjkav68qd/Caybg+WK5jkP1NrjH04ANNWYFpLBMvcD5K2kg67ExFlBjHFpYEL9O7DzX8BlrIacY52/3PDR5OOV2ryTPOIwf8mUvY+Yul/R5iwHO+6NCSiHD8cpTDLhNfA4ECavGGVhEe40yzbx3Smd/wOCfAzIJHmw5jzqpmqmfatGa2ZtoplwDrkV+n39SQbcbqXfZ1//x48qnrHv47XyU/VbgUINxVAAAAAElFTkSuQmCC);
}
.MuiBackdrop-root {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.lightmode-active .MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0);
}
.MuiLinearProgress-barColorPrimary {
  /* background-color: #51d4d8 !important; */
  background-image: linear-gradient(
    296deg,
    #ff69c5,
    #51d4d8,
    #a767ff
  ) !important;
}

:root {
  /* Glitter Color */
  --clr-purple: #ff69c5;
  --clr-purple-lt: #f5d7e9;

  /* Dark Mode Color */
  --clr-drkx: #000000;
  --clr-dkr: #222121;
  --clr-dk: rgb(44, 43, 43);
  --clr-d: #3c3c3c;
  --clr-dmc: #2a4c4b;

  /* Light Mode Color */
  --clr-ltrx: #ffffff;
  --clr-lt: #f9f9f9;
  --clr-l: #bebebe;
  --clr-lmclr: rgba(118, 208, 213, 0.15);

  /* Primary Color */
  --clr-yellow-lt: #fff9b2;
}

@font-face {
  font-family: "Proxima Nova";
  src: url(/static/media/ProximaNova-Regular.ea03f826.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/static/media/Proxima Nova Bold.fcaab252.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/static/media/Proxima Nova Black.120f91ca.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src: local("ProximaNova"),
    url(/static/media/ProximaNova-Semibold.be42827a.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  src: local("ProximaNova"),
    url(/static/media/ProximaNova-Bold.7722451a.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 900;
  src: local("ProximaNova"),
    url(/static/media/ProximaNova-Extrabld.026a945d.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url(/static/media/ProximaNova-Regular.b078618b.ttf) format("truetype");
}

@font-face {
  font-family: "proxima-nova-extra-condensed";
  font-weight: 800;
  src: local("ProximaNova"),
    url(/static/media/ProximaNovaExCn-Extrabld.a240783a.ttf) format("truetype");
}

.font-link {
  font-family: "Barlow", sans-serif;
}

.font-Mont {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: "10px";
  box-shadow: "inset 0 0 2px #b161ce";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b161ce;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b161ce;
}

:root {
  --clr-black: #000;
  --clr-white: #fff;
}

.flex-all-center: {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

